import { Testimonial } from './Testimonial';
import { Base, BaseLogo, BaseContentSlot, BaseText, BaseAuthorSlot } from './Base';
import { AuthorAvatar, AuthorInfo, AuthorName, AuthorPosition } from './Author';
import { Extended, ExtendedTextSlot, ExtendedButtonSlot, ExtendedText, ExtendedHeader, ExtendedHeaderSup, ExtendedStoryLink, ExtendedButton } from './Extended';
Testimonial.displayName = "TestimonialBase";
Base.displayName = "Testimonial.Base";
BaseLogo.displayName = "Testimonial.BaseLogo";
BaseContentSlot.displayName = "Testimonial.BaseContentSlot";
BaseText.displayName = "Testimonial.BaseText";
BaseAuthorSlot.displayName = "Testimonial.BaseAuthorSlot";
AuthorAvatar.displayName = "Testimonial.AuthorAvatar";
AuthorInfo.displayName = "Testimonial.AuthorInfo";
AuthorName.displayName = "Testimonial.AuthorName";
AuthorPosition.displayName = "Testimonial.AuthorPosition";
Extended.displayName = "Testimonial.Extended";
ExtendedTextSlot.displayName = "Testimonial.ExtendedTextSlot";
ExtendedButtonSlot.displayName = "Testimonial.ExtendedButtonSlot";
ExtendedText.displayName = "Testimonial.ExtendedText";
ExtendedHeader.displayName = "Testimonial.ExtendedHeader";
ExtendedHeaderSup.displayName = "Testimonial.ExtendedHeaderSup";
ExtendedStoryLink.displayName = "Testimonial.ExtendedStoryLink";
ExtendedButton.displayName = "Testimonial.ExtendedButton";
export { Testimonial as default };
export { Base, BaseLogo, BaseContentSlot, BaseText, BaseAuthorSlot };
export { AuthorAvatar, AuthorInfo, AuthorName, AuthorPosition };
export { Extended, ExtendedTextSlot, ExtendedButtonSlot, ExtendedText, ExtendedHeader, ExtendedHeaderSup, ExtendedStoryLink, ExtendedButton };