import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "tokens"],
    _excluded2 = ["children", "tokens"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { transparentize } from 'polished';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './Author.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var BaseAuthorName = function BaseAuthorName(_ref) {
  var children = _ref.children,
      tokens = _ref.tokens,
      rest = _objectWithoutProperties(_ref, _excluded);

  return _jsx(views.AuthorName, _objectSpread(_objectSpread({
    tokens: tokens,
    variant: "pMedium"
  }, rest), {}, {
    children: children
  }));
};
export var AuthorName = withDesignTokens(BaseAuthorName, function (_, theme) {
  return {
    color: theme.palette.neutrals.white[100]
  };
}, {
  light: function light() {
    return {};
  },
  dark: function dark() {
    return {};
  },
  twilioLight: function twilioLight() {
    return {};
  },
  twilioDark: function twilioDark() {
    return {};
  }
});

var BaseAuthorPosition = function BaseAuthorPosition(_ref2) {
  var children = _ref2.children,
      tokens = _ref2.tokens,
      rest = _objectWithoutProperties(_ref2, _excluded2);

  return _jsx(views.AuthorPosition, _objectSpread(_objectSpread({
    tokens: tokens,
    variant: "textTiny"
  }, rest), {}, {
    children: children
  }));
};

export var AuthorPosition = withDesignTokens(BaseAuthorPosition, function (_, theme) {
  return {
    color: transparentize(0.3, theme.palette.neutrals.white[100])
  };
}, {
  light: function light() {
    return {};
  },
  dark: function dark() {
    return {};
  },
  twilioLight: function twilioLight() {
    return {};
  },
  twilioDark: function twilioDark() {
    return {};
  }
});